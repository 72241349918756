import { VuexModule, Module, getModule, MutationAction } from "vuex-module-decorators"
import store from "@/store"
import VehicleHistoryAPI from "@/store/api/vehicleHistory"
import { VehicleHistoryPaginatedModel } from "@/store/models/vehicleHistory"
import { VehicleHistorySearchResult } from "@/store/models/registration/registration";


@Module({
    namespaced: true,
    name: 'vehicle_history',
    store,
    dynamic: true,
})
class VehicleHistory extends VuexModule{
    historyList: VehicleHistoryPaginatedModel = {} as VehicleHistoryPaginatedModel

    @MutationAction
    async getHistory(params: VehicleHistorySearchResult){
        let query = "";
        if (params) {
            query += "?";
            if (params.page) {
                query += `&page=${params.page}`;
            }
            if(params.page_size) {
                query += `&page_size=${params.page_size}`;
            }
            if(params.vehicle) {
                query += `&vehicle=${params.vehicle}`;
            }
            if(params.vehicleOwner) {
                query += `&vehicle__vehicle_owner=${params.vehicleOwner}`;
            }
            if(params.route) {
                query += `&route=${params.route}`;
            }
            if(params.rule_violated) {
                query += `&rule_violated=${params.rule_violated}`;
            }
            if(params.datetime) {
                query += `&datetime=${params.datetime}`;
            }
            if(params.road_link) {
                query += `&road_link=${params.road_link}`;
            }
        }

        const data = await VehicleHistoryAPI.getVehicleHistory(query)
        return { historyList: data as VehicleHistoryPaginatedModel}
    }
}
export default getModule(VehicleHistory);