






















































































































































































import { Vue, Component } from "vue-property-decorator";
import vehicle_history from "@/store/modules/vehicleHistory";
import helpers from "@/utils/helpers";
import { VehicleList, VehicleOwnerList } from "@/store/models/meta";
import { Route } from "@/store/models/route";
import { MetaModule as metaStore } from "@/store/modules";
import { VehicleHistorySearchResult } from "@/store/models/registration/registration";

@Component({
    components: {
    }
})
export default class HistoryReport extends Vue{
    vehicleNum = ""
    route = ""
    ruleViolation = ""
    transport_company_name = ""

    options1: VehicleList[] = [];
    options2: Route[] = [];
    options3 = [
        {
            value: 1,
            label: "Route Violation"
        },
        {
            value: 2,
            label: "Speed Violation"
        }
    ]
    options4: VehicleOwnerList[] = [];

    filter: VehicleHistorySearchResult = {
        page: null,
        page_size: null,
        vehicle: null,
        vehicleOwner: "",
        route: null,
        rule_violated: null
    };

    async created(){
        await vehicle_history.getHistory(this.filter);
        await metaStore.loadVehicleListDD();
        await metaStore.loadRoutesDD();
        await metaStore.loadAllVehicleOwner();
    }

    get HistoryReport(){
        return vehicle_history.historyList;
    }

    vehicleNumRemoteMethod(query: any) {
        if (query !== "") {
            setTimeout(() => {
                this.options1 = metaStore.vehicleList.filter((item) => {
                    return (
                        item.vehicleNumber.toLowerCase().indexOf(query.toLowerCase()) > -1
                    );
                });
            }, 200);
        } else {
            this.options1 = metaStore.vehicleList;
        }
    }

    routeRemoteMethod(query: any) {
        if (query !== "") {
            setTimeout(() => {
                this.options2 = metaStore.routesList.filter((item) => {
                    return (
                        item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                    );
                });
            }, 200);
        } else {
            this.options2 = metaStore.routesList;
        }
    }

    transportCompanyRemoteMethod(query: any) {
        if (query !== "") {
            setTimeout(() => {
                this.options4 = metaStore.vehicleOwnerList.filter((item) => {
                    return (
                        item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                    );
                });
            }, 200);
        } else {
            this.options4 = metaStore.vehicleOwnerList;
        }
    }

    toMomentDate(date: string){
        return helpers.toMomentDate(date);
    }

    fillValues(){
        this.filter.vehicle = parseInt(this.vehicleNum);
        this.filter.route = parseInt(this.route);
        this.filter.rule_violated = parseInt(this.ruleViolation);
        this.filter.vehicleOwner = this.transport_company_name;
    }

    async onSearch(){
        this.fillValues();
        await vehicle_history.getHistory(this.filter);
    }

    async onClear() {
        this.filter = {}
        await vehicle_history.getHistory(this.filter);
        this.vehicleNum = ""
        this.route = ""
        this.ruleViolation = ""
        this.transport_company_name = ""
    }

    async handlePageChange(val: number) {
        this.filter.page = val;
        this.fillValues();
        await vehicle_history.getHistory(this.filter);
    }
}
