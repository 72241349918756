import { getApi } from "./api"
import { Action } from "@/store/actionType"
import { VehicleHistoryModel } from "@/store/models/vehicleHistory"


export default new (class VehicleHistoryAPI {
    public async getVehicleHistory(query: string = ""): Promise<any> {
      const action = Action.vehicleHistory + query;
      const response = await getApi(action);
      
      return response as VehicleHistoryModel;
    }
})   